<template>
  <div class="media-zoom-carousel">
    <div class="media-zoom-carousel__container row flex media-gallery1">
      <div class="media-zoom-carousel__gallery">
        <carousel
          :per-page="1"
          :mouse-drag="false"
          :navigation-enabled="true"
          :pagination-enabled="false"
          :navigate-to="currentPage"
          navigation-next-label="<i class='h3 p10 hidden-xs-and-tablet pointer'>&#10095;</i>"
          navigation-prev-label="<i class='h3 p10 hidden-xs-and-tablet pointer'>&#10094;</i>"
          ref="zoomCarousel"
          class="media-zoom-carousel__carousel"
          :speed="carouselTransitionSpeed"
          @pageChange="pageChange"
        >
          <slide
            v-for="(images, index) in gallery"
            :key="images.src + index"
          >
            <div class="media-zoom-carousel__slide"
                 :class="{'video-container h-100 flex relative': images.video}"
            >
              <product-gallery-image
                v-if="hideImageAtIndex !== index"
                :image="images"
                :alt="productName | htmlDecode"
                :is-active="index === currentPage"
              />
              <product-video
                v-if="images.video && (index === currentPage)"
                v-bind="images.video"
                :index="index"
                @video-started="onVideoStarted"
              />
            </div>
          </slide>
        </carousel>
      </div>
      <div class="visible-xs-and-tablet mobile-navigation absolute w-100 pb10 px15">
        <div class="flex between-xs arrow-navigation">
          <div class="cl-alternative text-current-page">
            {{ currentPage + 1 }} z {{ gallery.length }}
          </div>
          <div class="arrow-container flex middle-xs">
            <div class="mr20" @click="SlideCarousel('backward')">
              <span class="arrow left" />
            </div>
            <div @click="SlideCarousel('forward')">
              <span class="arrow right" />
            </div>
          </div>
        </div>
      </div>
      <div id="menu" class="menu">
        <div v-for="(images, key) in gallery" :key="images.src + key" class="item" @mouseover="hoverEffect(key)" @mouseleave="leaveEffect()" :data-id="key">
          <div class="item-child" :data-active="activeValue(key)">
            <img
              key="lowQualityImage"
              width="60px"
              height="90px"
              :src="images.loading"
              ref="images"
              @mouseover="navigate(key)"
              :alt="productName | htmlDecode"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import ProductVideo from './ProductVideo'
import ProductGalleryImage from './ProductGalleryImage'

export default {
  name: 'ProductGalleryZoomCarousel',
  props: {
    currentSlide: {
      type: Number,
      required: false,
      default: 0
    },
    gallery: {
      type: Array,
      required: true
    },
    productName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      carouselTransitionSpeed: 300,
      currentPage: 0,
      hideImageAtIndex: null,
      currentActive: null
    }
  },
  components: {
    Carousel,
    Slide,
    ProductVideo,
    ProductGalleryImage
  },
  mounted () {
    this.$nextTick(() => {
      disableBodyScroll(this.$refs.thumbs)
    })
    this.currentPage = this.currentSlide
    this.carouselTransitionSpeed = 0
    setTimeout(() => {
      this.carouselTransitionSpeed = 300
    }, 100)
    if (this.$refs.zoomCarousel) {
      let navigation = this.$refs.zoomCarousel.$children.find(c => c.$el.className === 'VueCarousel-navigation')
      let pagination = this.$refs.zoomCarousel.$children.find(c => c.$el.className === 'VueCarousel-pagination')
      if (navigation !== undefined) {
        navigation.$on('navigationclick', this.increaseCarouselTransitionSpeed)
      }
      if (pagination !== undefined) {
        pagination.$on('paginationclick', this.increaseCarouselTransitionSpeed)
      }
    }
  },
  destroyed () {
    clearAllBodyScrollLocks()
  },
  methods: {
    SlideCarousel (value) {
      const carousel = this.$refs.zoomCarousel;
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'backward') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    hoverEffect (index) {
      this.currentActive = index
    },
    leaveEffect () {
      this.currentActive = null
    },
    activeValue (index) {
      if (index === this.currentActive) {
        return 0.5
      } else if ((this.currentActive === index + 1) || (this.currentActive === index - 1)) {
        return 0.25
      } else if ((this.currentActive === index + 2) || (this.currentActive === index - 2)) {
        return 0.12
      } else {
        return 0
      }
    },
    navigate (key) {
      this.$refs.zoomCarousel.goToPage(key)
    },
    increaseCarouselTransitionSpeed () {
      this.carouselTransitionSpeed = 500
    },
    pageChange (index) {
      this.currentPage = index
      this.hideImageAtIndex = null
    },
    onVideoStarted (index) {
      this.hideImageAtIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/animations/transitions';

.arrow-navigation {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    align-items: baseline;
  }
}
.text-current-page {
  font-size: 20px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 30px;
  }
}
.mobile-navigation {
  bottom: 0;
  .arrow-container {
    min-width: 100px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      padding-left: 20px;
    }
  }
  .arrow {
    border: solid #041D59;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 5px;
    right: 20px;
    bottom: 22px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      border-width: 0 5.5px 5.5px 0;
    }
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.media-zoom-carousel {
  * {
    box-sizing: border-box;
  }

  &__container{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
    padding: 15px;
    // height: 850px;
    max-height: 100%;
    justify-content: space-evenly;

    // @media (max-width: 767px){
    //   top: 50%;
    //   bottom: auto;
    //   height: auto;
    //   transform: translate3d(0, -50%, 0);
    // }
  }

  &__thumbs{
    list-style: none;
    padding-right: 20px;
    width:100%;
    max-width: 140px;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      display: none;
    }
  }

  &__thumb{
    margin-bottom: 20px;
    max-width: 100%;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > *{
      opacity: .9;
      will-change: opacity;
      transition: .3s opacity $motion-main;

      &:hover{
        opacity: 1;
      }
    }
  }

  &__gallery{
    margin-top: auto;
    margin-bottom: auto;
    max-width: 800px;
    // height: 100%;
    width: 100%;
    //  flex: 1;
    // @media (max-width: 767px) {
    //   height: auto;
    //   margin: 0;
    // }
  }

  // &__carousel {
  //   // height: 100%;
  // }

  &__slide{
    // height: 100%;
    max-height: 100%;
  }
}
.thumb-video{
  padding-bottom: calc(319% / (568 / 100));
}
.video-container {
  align-items: center;
  justify-content: center;
}
.media-gallery1 {
  // display: flex;
  // flex-wrap: wrap;

  &__thumbs {
    list-style: none;
    width: 100%;
    padding: 5px 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    overflow: auto;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      display: none;
    }
  }

  &__thumb {
    width: 90px;
    height: 90px;
    justify-content: center;
    margin: 10px 10px;
    cursor: pointer;
    display: inline-flex;

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      mix-blend-mode: multiply;
      // opacity: 0.9;

      // &:hover {
      //   opacity: 1;
      // }
    }
  }
}
// hover effect
#menu{
  margin-top: auto;
  // margin-top: 100px;
  display: flex;
  max-height: 150px;
  width: 100%;
  justify-content: center;
  overflow-x: auto;
  padding-bottom: 5px;
  &::-webkit-scrollbar {
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 16px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7DBA10;
    border-radius: 16px;
    height: 12px;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    display: none;
  }
  .item{
    display: flex;
    align-items: flex-end;
    img{
      width: 150px;
      height: auto;
    }
    .item-child {
      transform-origin: bottom;
      transition:  all 125ms ease-in-out;
      cursor: pointer;
      &[data-active="1"]{
        transform: scale(1);
      }
      &[data-active="0.75"]{
        transform: scale(0.88);
      }
      &[data-active="0.5"]{
        transform: scale(0.77);
      }
      &[data-active="0.25"]{
        transform: scale(0.66);
      }
      &[data-active="0.12"]{
        transform: scale(0.55);
      }
      &[data-active="0"]{
        transform: scale(0.5);
      }
    }
  }
}
</style>

<style lang="scss">
.media-zoom-carousel {
  .VueCarousel-wrapper,
  .VueCarousel-inner,
  .VueCarousel-slide {
    height: 100%;
  }
  .VueCarousel {
    .VueCarousel-navigation--disabled {
      opacity: 0 !important;
    }
    .VueCarousel-navigation-button {
      color: #FFF !important;
      background-color: #3859A6;
      font-size: 20px !important;
      transition: opacity 1s;
      @media (max-width: 767px) {
        background: transparent;
      }
    }
    .VueCarousel-navigation-next {
      right: -50px !important;
    }
    .VueCarousel-navigation-prev {
      left: -50px !important;
    }
  }
}
</style>
